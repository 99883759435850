<template>
  <b-modal
    id="add-course-modal"
    :title="$t('others.add_course')"
    size="xl"
    ok-variant="success"
    cancel-variant="danger"
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    centered
    no-close-on-backdrop
    @ok.prevent="saveClick"
    @cancel="$bvModal.hide('ClassInfoAddModal')"
    @hidden="resetModal"
  >
    <filters
      :search="true"
      :level-options="option"
      @filtered-items="filtered"
    />
    <b-row>
      <b-col cols="12">
        <b-table
          hover
          show-empty
          responsive
          :items="GET_COURSES_LIST.results"
          :fields="fields"
          :empty-text="$t('general.no_data_to_view')"
        >
          <!--
          sticky-header="300px"
          @scroll.native="handleScroll" -->
          <template #head()="{ label }">
            {{ $t(label) }}
          </template>
          <template #cell(phone_number)="data">
            <span class="text-nowrap">
              {{ data.value }}
            </span>
          </template>

          <template #cell(subject)="{ value }">
            {{ value.title[$i18n.locale] }}
          </template>

          <template #cell(actions)="{ item }">
            <b-form-checkbox
              :checked="selected_rows.includes(item.id)"
              @change="selectionChange($event, item.id)"
            />
            <!-- {{ item }} -->
          </template>
        </b-table>
        <!-- pagination -->
        <!-- slot-scope="props" -->
        <template>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">{{ $t("general.show_from") }}</span>
              <b-form-select
                v-model="params.page_size"
                :options="pageOptions"
                class="mx-1"
                @change="changePerPage"
              />
              <span class="text-nowrap">{{ $t("general.show_to") }}</span>
              <!-- из {{ props.total }} -->
            </div>
            <div>
              <b-pagination
                v-model="params.page"
                :total-rows="GET_COURSES_LIST.count"
                :per-page="params.page_size"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </b-col>
    </b-row>
    <!-- <b-overlay
      :show="isBusy"
      no-wrap
      opacity="0.5"
    /> -->
  </b-modal>
</template>

<script>
import {
  BRow,
  BModal,
  BCol,
  BTable,
  BFormCheckbox,
  BFormSelect,
  BPagination,
  // BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import filters from '@/components/filters.vue'
import toast from '@/mixins/toast'

export default {
  components: {
    BRow,
    BModal,
    BCol,
    // BOverlay,
    BTable,
    BFormCheckbox,
    filters,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  mixins: [toast],
  data() {
    return {
      pageOptions: [3, 5, 10],
      params: {
        page_size: 10,
        page: 1,
      },
      option: [
        { title: 'Square' },
        { title: 'Rectangle' },
        { title: 'Rombo' },
        { title: 'Romboid' },
      ],
      selected_rows: [],
      level: [],
      fields: [
        {
          label: 'users.course_title',
          key: 'title',
        },
        {
          label: 'courses.price',
          key: 'price',
        },
        {
          label: 'courses.subjects',
          key: 'subject',
        },
        {
          label: 'general.actions',
          key: 'actions',
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_COURSES_LIST']),
  },
  watch: {
    defSelected(val) {
      this.selected_rows = val
    },
  },
  props: {
    defSelected: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.FETCH_COURSES_LIST(this.params).then(() => {
      this.selected_rows = this.defSelected
    })
  },
  methods: {
    ...mapActions('courses', ['FETCH_COURSES_LIST', 'UPDATE_COURSE']),
    saveClick() {
      this.$emit('save', this.selected_rows)
    },
    async resetModal() {
      this.selected_rows = []
      this.$emit('reset')
    },
    selectionChange(val, id) {
      // if (val) {
      //   if (this.selected_rows.includes(id)) {
      //     return
      //   }
      //   this.selected_rows.push(id)
      // }
      // this.selected_rows = this.selected_rows.filter(item => item !== id)
      const index_id = this.selected_rows.indexOf(id)
      if (val) this.selected_rows.push(id)
      else this.selected_rows.splice(index_id, 1)
    },
    filtered(val) {
      this.params = { ...this.params, ...val }
      this.FETCH_COURSES_LIST(this.params)
    },
    changePerPage(size) {
      this.params.page_size = size
      this.params.page = 1
      this.FETCH_COURSES_LIST(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_COURSES_LIST(this.params)
    },
  },
}
</script>

<style></style>
