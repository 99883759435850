<template>
  <b-modal
    id="AddModalBranch"
    :title="isEdit ? $t('Редактировать сотрудника') : $t('Добавить сотрудника')"
    size="lg"
    ok-variant="success"
    cancel-variant="danger"
    ok-title="Сохранить"
    cancel-title="Отменит"
    centered
    no-close-on-backdrop
    @ok.prevent="addEditBranch"
    @cancel="$bvModal.hide('AddModalBranch')"
    @show="openModal"
  >
    <validation-observer ref="usersValidation">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('Название филиала')"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Название филиала"
            >
              <b-form-input
                id="title"
                v-model="branch.title"
                :placeholder="$t('Название филиала')"
                :state="errors.length > 0 ? false : null"
                name="reset-password-login"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            :label="$t('Лимит пользователей')"
            label-for="users_limit"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Лимит пользователей"
            >
              <b-form-input
                id="users_limit"
                v-model="branch.capacity"
                type="number"
                :placeholder="$t('Лимит пользователей')"
                :state="errors.length > 0 ? false : null"
                name="reset-password-login"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Дата от -->
          <b-form-group
            :label="$t('Доступ')"
            label-for="data"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('Доступ')"
            >
              <vue-date-picker
                id="data"
                v-model="branch.access"
                :state="errors.length > 0 ? false : null"
                :other-props="{ 'is-range': true }"
                :placeholder="$t('Доступ')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <!-- <b-form-group
            :label="$t('Лимит памяти (GB)')"
            label-for="memory"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Лимит памяти (GB)"
            >
              <b-form-input
                id="memory"
                v-model="branch.storage"
                :placeholder="$t('Лимит памяти (GB)')"
                :state="errors.length > 0 ? false : null"
                name="reset-password-login"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->

          <b-form-group
            :label="$t('Код филлиала')"
            label-for="branch_code"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              name="Код филлиала"
            >
              <b-form-input
                id="branch_code"
                v-model="branch.branch_code"
                :placeholder="$t('Код филлиала')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <div class="mt-1">
              <b-form-group :label="$t('Статус')">
                <b-row>
                  <b-col cols="3">
                    <b-form-checkbox
                      v-model="branch.is_active"
                      name="check-button"
                      switch
                      inline
                    />
                  </b-col>
                  <b-col
                    cols="9"
                    class="text-right"
                  >
                    <b-button
                      variant="primary"
                      @click="showAddCourseModal"
                    >
                      {{ $t("others.add_course") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-form-group>
        </b-col>
        <validation-observer />
      </b-row>
    </validation-observer>

    <AddCourseModal
      :def-selected="defCourses"
      @save="saveCourse"
      @reset="resetCourse"
    />
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import VueDatePicker from '@/components/VueDatePicker.vue'
import AddCourseModal from './AddCourseModal.vue'

export default {
  name: 'AddModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    VueDatePicker,
    AddCourseModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    branchData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      required,
      branch: {
        title: '',
        access: null,
        storage: '',
        is_active: true,
        capacity: null,
        branch_code: '',
        courses: [],
      },
      defCourses: [],
    }
  },
  methods: {
    ...mapActions('branches', [
      'CREATE_BRANCH',
      'UPDATE_BRANCH',
      'FETCH_APPROVED_BRANCH_LIST',
      'FETCH_MODIFIED_BRANCH_LIST',
      'FETCH_REJECT_BRANCH_LIST',
    ]),
    saveCourse(item) {
      this.branch.courses = item
      this.$nextTick(() => {
        this.$bvModal.hide('add-course-modal')
      })
    },
    resetCourse() {
      this.defCourses = []
      this.$nextTick(() => {
        this.$bvModal.hide('add-course-modal')
      })
    },
    openModal() {
      if (this.isEdit) {
        const { access_end_date, access_start_date, ...rest } = this.branchData
        this.branch = {
          access: {
            start: access_start_date,
            end: access_end_date,
          },
          ...rest,
        }
      } else {
        this.branch = {
          title: '',
          access: null,
          storage: '',
          is_active: true,
          capacity: null,
          branch_code: '',
          courses: [],
        }
      }
    },

    showAddCourseModal() {
      this.defCourses = this.branch.courses
      this.$nextTick(() => {
        this.$bvModal.show('add-course-modal')
      })
      // this.$nextTick(() => {
      //   this.$bvModal.show("addCourseModal");
      //   console.log("showAddCourseModal");
      // });
    },

    addEditBranch() {
      this.$refs.usersValidation.validate().then(async res => {
        if (res) {
          const { access, ...rest } = this.branch
          const data = {
            access_start_date: access.start,
            access_end_date: access.end,
            ...rest,
          }
          if (!data.id) {
            this.CREATE_BRANCH(data)
              .then(() => {
                this.$_successToast(this.$t('general.added'))
                this.$bvModal.hide('AddModalBranch')
                this.FETCH_APPROVED_BRANCH_LIST()
              })
              .catch(() => {
                this.$_errorToast(this.$t('general.error'))
              })
          } else {
            this.$set(data, 'status', 'edited')
            this.$delete(data, 'data')
            try {
              // eslint-disable-next-line no-unused-expressions
              this.$can('manage', 'super_admin')
                ? await this.UPDATE_BRANCH(data)
                : await this.UPDATE_BRANCH({ data, id: data.id })
              this.$bvModal.hide('AddModalBranch')
              this.$_successToast('Успешно отредактировано')
              this.FETCH_MODIFIED_BRANCH_LIST()
              this.FETCH_APPROVED_BRANCH_LIST()
            } catch {
              this.$_errorToast(this.$t('general.error'))
            }
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
