var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"AddModalBranch","title":_vm.isEdit ? _vm.$t('Редактировать сотрудника') : _vm.$t('Добавить сотрудника'),"size":"lg","ok-variant":"success","cancel-variant":"danger","ok-title":"Сохранить","cancel-title":"Отменит","centered":"","no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.addEditBranch.apply(null, arguments)},"cancel":function($event){return _vm.$bvModal.hide('AddModalBranch')},"show":_vm.openModal}},[_c('validation-observer',{ref:"usersValidation"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Название филиала'),"label-for":"title"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Название филиала"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t('Название филиала'),"state":errors.length > 0 ? false : null,"name":"reset-password-login"},model:{value:(_vm.branch.title),callback:function ($$v) {_vm.$set(_vm.branch, "title", $$v)},expression:"branch.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Лимит пользователей'),"label-for":"users_limit"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Лимит пользователей"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users_limit","type":"number","placeholder":_vm.$t('Лимит пользователей'),"state":errors.length > 0 ? false : null,"name":"reset-password-login"},model:{value:(_vm.branch.capacity),callback:function ($$v) {_vm.$set(_vm.branch, "capacity", $$v)},expression:"branch.capacity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Доступ'),"label-for":"data"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Доступ')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-date-picker',{attrs:{"id":"data","state":errors.length > 0 ? false : null,"other-props":{ 'is-range': true },"placeholder":_vm.$t('Доступ')},model:{value:(_vm.branch.access),callback:function ($$v) {_vm.$set(_vm.branch, "access", $$v)},expression:"branch.access"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Код филлиала'),"label-for":"branch_code"}},[_c('validation-provider',{attrs:{"rules":"","name":"Код филлиала"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"branch_code","placeholder":_vm.$t('Код филлиала'),"state":errors.length > 0 ? false : null},model:{value:(_vm.branch.branch_code),callback:function ($$v) {_vm.$set(_vm.branch, "branch_code", $$v)},expression:"branch.branch_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"mt-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Статус')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.branch.is_active),callback:function ($$v) {_vm.$set(_vm.branch, "is_active", $$v)},expression:"branch.is_active"}})],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"9"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.showAddCourseModal}},[_vm._v(" "+_vm._s(_vm.$t("others.add_course"))+" ")])],1)],1)],1)],1)],1)],1),_c('validation-observer')],1)],1),_c('AddCourseModal',{attrs:{"def-selected":_vm.defCourses},on:{"save":_vm.saveCourse,"reset":_vm.resetCourse}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }