<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mt-2"
      >
        <b-table
          hover
          show-empty
          :fields="fields"
          :items="GET_REJECTED_BRANCH"
          style-class="slots_text_align vgt-table bordered"
        >

          <template
            #empty
          >
            <div class="text-center text-secondary">
              {{ $t('general.no_data_to_view') }}
            </div>
          </template>
          <template
            #cell(actions)
          >
            <div class="d-flex align-items-center no-wrap">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon mr-50 p-25"
                size="sm"
              >
                <feather-icon
                  size="22"
                  icon="CheckIcon"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon mr-50 p-25"
                size="sm"
              >
                <feather-icon
                  size="22"
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon p-25"
                size="sm"
              >
                <feather-icon
                  size="22"
                  icon="XIcon"
                />
              </b-button>
            </div>
          </template>

        </b-table>

        <!-- pagination -->
        <!-- slot-scope="props" -->
        <!-- <template>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">{{ $t('general.show_from') }}</span>
              <b-form-select
                v-model="params.page_size"
                :options="['5', '10', '15']"
                class="mx-1"
                @change="changePerPage"
              />
              <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
            </div>
            <div>
              <b-pagination
                v-model="params.page"
                :total-rows="1"
                :per-page="params.page_size"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  // BFormSelect,
  // BPagination,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BTable,
    // BFormSelect,
    // BPagination,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      fields: [
        {
          label: 'Название филиала',
          key: 'title',
        },
        {
          label: 'Лимит пользователей (Ед.)',
          key: 'capacity',
        },
        {
          label: 'Автор',
          key: 'user',
        },
        {
          label: 'Дата создания',
          key: 'access_start_date',
        },
        {
          label: 'Дата окончения',
          key: 'access_end_date',
        },
        {
          label: 'Статус',
          key: 'is_active',
        },
        {
          label: 'Комментарии',
          key: 'reason',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('branches', ['GET_REJECTED_BRANCH']),
  },
  mounted() {
    this.FETCH_REJECT_BRANCH_LIST()
  },
  methods: {
    ...mapActions('branches', ['FETCH_REJECT_BRANCH_LIST']),
    submit() {

    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      // this.FETCH_USERS_LIST(this.params)
    },
    changePage(value) {
      this.params.page = value
      // this.FETCH_USERS_LIST(this.params)
    },
    filter(val) {
      this.params.search = val.search
      // this.FETCH_USERS_LIST(val)
    },
  },
}
</script>

<style>

</style>
